body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0px;
  width: 100%;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Good Times';
  src: url('./assets/fonts/good\ times\ rg.ttf');
}

@font-face {
  font-family: "VT323";
  src: local("VT323"),
    url("./assets/fonts/VT323-Regular.ttf") format("truetype");
}