.error {
    min-height: 100vh;
    position: relative;
    padding: 240px 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 70px;
}

.error__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.error__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.error__message {
    text-align: center;
    color: #181828;
}

.message__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 5.6rem;
    max-width: 960px;
    margin: 0 auto;
}

.four-zero-four {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 10.6rem;
    max-width: 1000px;
    margin: 0 auto;
}

.message__text {
    font-family: 'Montserrat', sans-serif;
    line-height: 42px;
    font-size: 18px;
    padding: 0 60px;
    max-width: 500px;
    margin: auto;
}

.error__nav {
    max-width: 600px;
    margin: 40px auto 0;
    text-align: center;
}

.e-nav__form {
    position: relative;
    height: 45px;
    overflow: hidden;
    width: 170px;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #212121;
    padding-left: 10px;
    padding-right: 46px;
}

.e-nav__icon {
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #212121;
    -webkit-transition: color .25s ease;
    transition: color .25s ease;
}

.e-nav__link {
    height: 45px;
    line-height: 45px;
    width: 170px;
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
    border: 2px solid #ffffff;
    color: #ffffff;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .1rem;
    position: relative;
    overflow: hidden;
}

.e-nav__link:before {
    content: '';
    height: 200px;
    background: #ffffff;
    position: absolute;
    top: 70px;
    right: 70px;
    width: 260px;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg);
}

.e-nav__link:after {
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 999;
    position: relative;
}

.e-nav__link:after {
    content: "Home Page";
}

.e-nav__link:hover:before {
    top: -60px;
    right: -50px;
}

.e-nav__link:hover {
    color: #000;
}

.e-nav__link:nth-child(2):hover:after {
    color: #fff;
}

.lamp {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    margin: 0px auto;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform-origin: center top;
    animation-timing-function: cubic-bezier(0.6, 0, 0.38, 1);
    animation: move 5.1s infinite;
}

@keyframes move {
    0% {
        transform: rotate(40deg);
    }

    50% {
        transform: rotate(-40deg);
    }

    100% {
        transform: rotate(40deg);
    }
}

.cable {
    width: 8px;
    height: 248px;
    background-image: linear-gradient(rgba(32, 148, 218, 0.7), rgba(193, 65, 25, 1)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
}

.cover {
    width: 200px;
    height: 80px;
    background: #0bd5e8;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    position: relative;
    z-index: 200;
}

.in-cover {
    width: 100%;
    max-width: 200px;
    height: 20px;
    border-radius: 100%;
    background: #08ffff;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0px auto;
    bottom: -9px;
    z-index: 100;
}

.in-cover .bulb {
    width: 50px;
    height: 50px;
    background-color: #08fffa;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -20px;
    margin: 0px auto;
    -webkit-box-shadow: 0 0 15px 7px rgba(0, 255, 255, 0.8), 0 0 40px 25px rgba(0, 255, 255, 0.5), -75px 0 30px 15px rgba(0, 255, 255, 0.2);
    box-shadow: 0 0 25px 7px rgba(127, 255, 255, 0.8), 0 0 64px 47px rgba(0, 255, 255, 0.5), 0px 0 30px 15px rgba(0, 255, 255, 0.2);
}


.light {
    width: 200px;
    height: 0px;
    border-bottom: 900px solid rgba(44, 255, 255, 0.24);
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 270px;
    margin: 0px auto;
    z-index: 1;
    border-radius: 90px 90px 0px 0px;
}

.error {
    overflow: hidden;
    max-height: 100vh;
}

@media (max-width: 1400px) {
    .lamp {
        zoom: .5;
    }

    .error__content {
        top: 55%;
    }

    .message__title {
        font-size: 3.5rem;
    }
}

@media (max-width: 900px) {

    .message__title {
        font-size: 34px;

    }

    .error__content {
        top: 55%;
    }
}

@media (max-width: 950px) {
    .lamp__wrap {
        max-height: 100vh;
        overflow: hidden;
        max-width: 100vw;
    }

    .error__social {
        bottom: 30px;
        top: auto;
        transform: none;
        width: 100%;
        position: fixed;
        left: 0;
    }

    .e-social__icon {
        display: inline-block;
        padding-right: 30px;
    }

    .e-social__icon:last-child {
        padding-right: 0;
    }

    .e-social__icon {
        padding-bottom: 0;
    }
}

@media (max-width: 750px) {

    body,
    html {
        max-height: 100vh;
    }

    .error__content {
        position: static;
        margin: 0 auto;
        transform: none;
        padding-top: 300px;
    }

    .error {
        padding-top: 0;
        padding-bottom: 100px;
        height: 100vh;
    }
}

@media (max-width: 650px) {
    .message__title {
        font-size: 36px;
        padding-bottom: 20px;
    }

    .message__text {
        font-size: 16px;
        line-height: 2;
        padding-right: 20px;
        padding-left: 20px;
    }

    .lamp {
        zoom: .6;
    }

    .error__content {
        padding-top: 180px;
    }
}

@media (max-width: 480px) {

    .message__title {
        font-size: 30px;
    }

    .message__text {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 15px;
    }

    .error__nav {
        margin-top: 20px;
    }
}