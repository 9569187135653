.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hide {
  display: none !important;
  height: 0% !important;
  opacity: 0;
}

h1 {
  font-family: Montserrat, sans-serif;
}

p {
  font-family: Montserrat, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.justifyAround {
  justify-content: space-around !important;
}

.invalidInput {
  border: 2px solid red !important;
}

.invalidInput::placeholder {
  color: rgb(142, 21, 21);
}

.successfulInput {
  border: 2px solid #3FD28F !important;
}

.successfulInput::placeholder {
  color: #3FD28F;
}

.parentErrorContainer {
  align-content: center;
  align-items: center;
  background-color: #2140d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 768px;
  min-height: inherit;
  position: relative;
  top: -1px;

  @media only screen and (max-width: 430px) {
    h1 {
      color: white;
      font-family: 'VT323';
      font-size: 57px;
      font-weight: 100;
      margin: 10px 0px 25px 0px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 431px) {
    h1 {
      color: white;
      font-family: 'VT323';
      font-size: 70px;
      font-weight: 100;
      margin: 10px 0px 25px 0px;
      text-align: center;
    }
  }

  p {
    color: #ffffff82;
    font-family: 'VT323' !important;
    font-size: 20px;
    padding: 0px;
    text-align: center;
  }
}


.parentSignupContainer {
  align-content: center;
  align-items: center;
  background-color: #2140d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 768px;
  min-height: inherit;
  position: relative;
  top: -1px;

  .logo {
    max-width: 200px;
    min-width: 140px;
    width: 40%;
  }

  .logoName {
    padding: 20px 0px;
    width: 300px;
  }

  .signupElements {
    height: 0%;
    opacity: 0;
  }

  @media only screen and (max-width: 430px) {
    h1 {
      color: white;
      font-family: 'VT323' !important;
      font-size: 57px;
      font-weight: 100;
      margin: 10px 0px 25px 0px;
      text-align: center;
      width: 400px;
    }
  }

  @media only screen and (min-width: 431px) {
    h1 {
      color: white;
      font-family: 'VT323' !important;
      font-size: 70px;
      font-weight: 100;
      margin: 10px 0px 25px 0px;
      text-align: center;
      width: 400px;
    }
  }

  p {
    color: #ffffff82;
    font-family: 'VT323' !important;
    font-size: 20px;
    margin: 0px 0px;
    padding: 0px;
    text-align: center;
    width: 500px;
  }

  .formContainer {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 500px;
  }

  .formContainer>form {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .formInputs>div {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .carTypeSelection i {
    border: 1px #3f53ba;
    border-radius: 10px;
    border-style: outset;
    color: #63e3fd8a;
    cursor: pointer;
    font-size: 28px;
    margin: 15px 3px;
    padding: 7px;
  }

  .carTypeSelection i:hover {
    color: #dee821d5;
  }

  .carTypeSelection>.active {
    border-color: #3f53ba;
    color: #dee821d5;
  }

  button {
    /* margin: 10px 0px -2px 0px; */
    background: #ffee20;
    border: 1px solid #a4afe5;
    border-radius: 10px;
    color: #2140d9;
    cursor: pointer;
    /* font-weight: bold; */
    font-family: 'VT323';
    font-size: 20px;
    /* height: 32px; */
    padding: 12px;
    width: 250px;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    opacity: 1;

    button {
      width: 150px;
    }
  }

  .cancelButton {
    background: #ffee21;
    border: 2px solid #2040d9;
    color: #2040d9;
    margin: 0px 5px 0 0;
    width: 50px !important;
  }


  .disabledButton {
    cursor: not-allowed;
  }

  input {
    border: 1px solid #707070;
    border-radius: 8px;
    color: #000000;
    font-family: 'VT323';
    font-size: 20px;
    height: 15px;
    margin: 15px 0px;
    padding: 15px;
    text-align: center;
    width: 220px;
  }

  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(169, 169, 169);
    opacity: 1;
    /* Firefox */
  }
}

.link {
  margin-top: 5px;
  position: relative;
  text-align: center;

  span {
    color: #26d8d7;
    cursor: pointer;
    text-decoration: underline;
  }
}

.parentHeaderContainer {
  height: 90px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100
}

.navContainer {
  padding: 15px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.headerNav {
  align-items: center;
  background: #2140d9;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 20px;
  justify-content: space-between;
  margin: 0px 15px;
  padding: 15px;
  position: relative;
  top: 10px;
  z-index: 999;

  img {
    width: 40px;
  }

  i {
    color: white;
    cursor: pointer;
    font-size: 24px;
  }

  i:hover {
    color: #ffea00;
  }
}

.accountNav {
  align-items: center;
  background: #2140d9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 0px;
  justify-content: center;
  left: 0px;
  margin: 0px 15px;
  padding: 15px;
  position: relative;
  top: -40px;
  width: auto;
  z-index: 998;

  h1 {
    color: white;
    cursor: pointer;
    font-family: 'VT323' !important;
    font-size: 70px;
    font-weight: 100;
    margin: 30px 0px;
  }

  h1:hover {
    color: #ffea00;
    text-decoration: underline;
  }

  @media only screen and (max-width: 430px) {
    h1 {
      font-size: 53px;
    }
  }
}

.accountDetailsContainer {
  bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;
  position: absolute;
  width: 100%;

  .accountDetailsModal {
    align-items: center;
    background: white;
    border: 3px solid #2140D9;
    border-radius: 10px;
    box-shadow: 5px 7px 0px #7d21d996;
    display: flex;
    display: none;
    flex-direction: column;
    flex-wrap: nowrap;
    height: calc(100vh - 95px);
    justify-content: center;
    left: 0px;
    margin: 0px 15px;
    padding: 10px;
    position: relative;
    width: auto;
    z-index: 998;
  }
}

.projectModalContainer {
  margin: 15px;
  max-width: 670px;
  opacity: 0;
  padding: 10px;
  position: fixed;
  width: 92%;
  z-index: -999;
}

.projectModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -35px;
  background: #ffffffc4;
  padding: 5px 25px;
  border-radius: 10px;
  color: #004982;
  border: 3px solid #2040d9;

  h1 {
    font-size: 14px;
    color: #004982;
  }

  p {
    color: #004982;
    font-size: 10px;
  }

  >div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
  }

  i {
    cursor: pointer;
  }

  @media only screen and (max-height: 640px) {
    h1 {
      font-size: 12px;
    }

    p {
      font-size: 8px;
    }
  }
}

.deviceModal {
  background: white;
  border: 3px solid #2140D9;
  border-radius: 10px;
  bottom: 25px;
  box-shadow: 5px 7px 0px rgba(125, 33, 217, 0.5882352941);
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  padding: 10px;
  z-index: -999;
  position: relative;

  @media only screen and (max-height:640px) {
    bottom: 7px !important;
  }
}

.deviceModal {
  .cancelButton {
    @media only screen and (min-width: 430px) {
      color: #b5b5b5;
      cursor: pointer;
      font-size: 22px;
      position: absolute;
      right: 10px;
      text-align: right;
      top: 10px;
    }

    @media only screen and (max-width: 431px) {
      color: #b5b5b5;
      cursor: pointer;
      font-size: 22px;
      position: absolute;
      right: 12px;
      text-align: right;
      top: 8px;
    }

    @media only screen and (max-height: 640px) {
      font-size: 19px;
    }
  }

  .expandButton {
    @media only screen and (min-width: 430px) {
      color: #b5b5b5;
      cursor: pointer;
      display: none;
      font-size: 22px;
      position: absolute;
      right: 10px;
      text-align: right;
      top: 10px;
    }

    @media only screen and (max-width: 431px) {
      color: #b5b5b5;
      cursor: pointer;
      display: none;
      font-size: 22px;
      position: absolute;
      right: 8px;
      text-align: right;
      top: 8px;
    }

    @media only screen and (max-height: 640px) {
      font-size: 19px;
    }
  }

  .minimiseButton {
    @media only screen and (min-width: 430px) {
      color: #b5b5b5;
      cursor: pointer;
      display: block;
      font-size: 22px;
      position: absolute;
      right: 40px;
      text-align: right;
      top: 10px;
      z-index: 100;
    }

    @media only screen and (max-width: 431px) {
      color: #b5b5b5;
      cursor: pointer;
      display: block;
      font-size: 22px;
      position: absolute;
      right: 38px;
      text-align: right;
      top: 8px;
      z-index: 100;
    }

    @media only screen and (max-height: 640px) {
      font-size: 19px;
    }
  }
}

.deviceModal>.cancelButton:hover {
  color: #932e2e;
}

.deviceModal>.expandButton:hover {
  color: #004982;
}

.deviceModal>.minimiseButton:hover {
  color: #004982;
}

.deviceInfo {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0px 15px;
  width: 90%;

  >div {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .status {
    color: gray;
    font-size: 10px;
    margin: 8px 0px 0px 16px;
    text-transform: uppercase;
  }

  .status.available {
    color: #13AD67 !important;
  }

  .Private {
    font-family: Montserrat, sans-serif;
    padding: 2px 7px;
    margin: 7px 0px 0px 16px;
    font-size: 10px;
    background-color: #C44B4B;
    color: white;
    border-radius: 25px;
  }

  .Public {
    display: none;
    font-family: Montserrat, sans-serif;
    padding: 2px 7px;
    margin: 7px 0px 0px 16px;
    font-size: 10px;
    background-color: #C44B4B;
    color: white;
    border-radius: 25px;
  }

  .Restricted {
    display: none;
    font-family: Montserrat, sans-serif;
    padding: 2px 7px;
    margin: 7px 0px 0px 16px;
    font-size: 10px;
    background-color: #C44B4B;
    color: white;
    border-radius: 25px;
  }

  @media only screen and (min-width: 430px) {
    h1 {
      color: #121f62;
      font-size: 14px;
      margin: 8px 0px 0px 0px;
    }

    >p {
      color: #121f62;
      font-size: 14px;
      margin: 8px 0px;
    }

    >.directions {
      color: #a1a1a1;
      font-size: 12px;
      margin: 5px 0px;
    }
  }

  @media only screen and (max-width: 430px) {
    h1 {
      color: #121f62;
      font-size: 12px;
      font-weight: bold;
      margin: 8px 0px 0px 0px;
    }

    >p {
      color: #121F62;
      font-size: 11px;
      margin: 8px 0px;
    }

    >.directions {
      color: #a1a1a1;
      font-size: 9px;
      margin: 5px 0px;
    }

    .status {
      color: gray;
      font-size: 8px;
      margin: 7px 0px 0px 5px;
      text-transform: uppercase;
    }
  }

  @media only screen and (max-height: 640px) {
    h1 {
      font-size: 10px;
    }

    p {
      font-size: 5.5px;
    }

    >.directions {
      font-size: 7px;
    }

    .status {
      font-size: 6px;
    }
  }
}

.deviceMetrics {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 0 3px;
}

.metricContainer {
  align-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin: 4px 0;
  width: 21%;
  padding: 6px 0;

  @media only screen and (max-width: 430px) {
    p:first-child {
      color: #828282;
      font-size: 7px;
      margin: 4px 0px 0px;
    }

    p:nth-child(2) {
      color: #828282;
      font-size: 7px;
      margin: 0px;
    }

    p:last-child {
      color: #626262;
      font-size: 11px;
      font-weight: 800;
      margin: 4px 0px;
    }
  }

  @media only screen and (min-width: 430px) {
    p:first-child {
      color: #828282;
      font-size: 12px;
      margin: 8px 0px 0px;
    }

    p:nth-child(2) {
      color: #828282;
      font-size: 10px;
      margin: 0px;
    }

    p:last-child {
      color: #626262;
      font-size: 16px;
      font-weight: 800;
      margin: 8px 0px;
    }
  }
}

.deviceOptions {
  align-content: center;
  align-items: center;
  display: flex;
}

.optionsContainer {
  align-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 50px;
  justify-content: space-evenly;
  width: 65px;
  margin: 0 15px 0 0;
  padding: 12px 5px 0 5px;
  border: 1px solid #d06a00;

  p {
    color: #d06a00;
    line-height: 0;
    margin: 0 0 7px 0;
    pointer-events: none;
    font-weight: 700;
    font-size: 12px;
  }

  img {
    height: 30px;
    width: 30px;
    pointer-events: none;
    filter: invert(48%) sepia(85%) saturate(2992%) hue-rotate(12deg) brightness(91%) contrast(104%);
  }

  @media only screen and (max-width: 430px) {
    height: 40px;
    width: 52px;

    p {
      font-size: 9px;
    }

    img {
      height: 30px;
      width: 23px;
    }
  }

  @media only screen and (max-height: 640px) {
    height: 35px;
    width: 47px;

    p {
      font-size: 7px;
    }

    img {
      height: 26px;
      width: 19px;
    }
  }
}

.optionsContainer:hover {
  background-color: #DCE5F8;
}

.optionsContainer.selected {
  border-width: 3px;
}

@media only screen and (max-width: 430px) {
  .deviceButtons {
    display: flex;
    height: 35px;
    justify-content: space-between;
    margin: 10px 10px;

    button {
      border: 0px;
      border-radius: 10px;
      color: white;
      cursor: pointer;
      font-size: 10px;
      font-weight: 900;
    }

    button:hover {
      border: 3px solid #218e4d;
    }
  }
}

@media only screen and (min-width: 430px) {
  .deviceButtons {
    display: flex;
    height: 45px;
    justify-content: space-between;
    margin: 15px;

    button {
      border: 0px;
      border-radius: 10px;
      color: white;
      cursor: pointer;
      font-size: 14px;
      font-weight: 900;
    }

    button:hover {
      color: #ffee20;
      filter: drop-shadow(2px 4px 3px #ae7ae3);
    }
  }
}

@media only screen and (max-height: 640px) {
  .deviceButtons {
    display: flex;
    height: 33px;
    justify-content: space-between;
    margin: 10px 10px;

    button {
      border: 0px;
      border-radius: 10px;
      color: white;
      cursor: pointer;
      font-size: 9px;
      font-weight: 900;
    }

    button:hover {
      border: 3px solid #218e4d;
    }
  }
}

.deviceButtons>.reserveButton {
  background: #9b9b9b;
  cursor: not-allowed;
  width: 76%;
}

.deviceButtons>.reserveButton.available {
  background: #1441e2 !important;
  cursor: pointer !important;
}

.deviceButtons>.paymentButton {
  background: #1441e2;
  width: 83%;
}

.deviceButtons>.likeButton {
  background: #0c2da1;
  width: 15%;
}

.deviceButtons>.closeButton {
  background: #a54343;
  width: 15%;
}

.deviceButtons>.directionsButton {
  background: #0c2da1;
  cursor: pointer;
  width: 22%;
}

.deviceButtons>.directionsButton:disabled {
  background: #9b9b9b;
  cursor: not-allowed;
}

.deviceButtons-SpotActive {
  border-radius: 0px !important;
  border-top: 3.5px solid #13AD67;
}

.deviceButtons>.walletInsufficient {
  background: #9b9b9b;
  cursor: not-allowed;
}

.reserveOptionsContainer {
  margin: 0px 15px;
  display: block;

  >p {
    @media only screen and (max-width: 430px) {
      font-size: 10px;
    }

    @media only screen and (min-width: 431px) {
      font-size: 12px;
    }

    @media only screen and (min-height: 640px) {
      font-size: 8px;
    }

    color: #151f5e;
    font-weight: 700;
  }
}

.reserveOption {
  align-content: center;
  align-items: center;
  background: whitesmoke;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
  max-height: 90px;
  min-height: 50px;
  padding: 0px 35px;

  @media only screen and (max-width: 430px) {
    padding: 0px 20px !important;

    p {
      color: #797979;
      font-size: 10px;
      padding: 0px 0px 0px 25px;
      text-align: left;
      width: 50%;
    }

    >div {
      height: inherit;
      width: 30%;
    }

    >div:nth-child(1) {
      align-items: center;
      display: flex;
      height: auto;
      justify-content: center;
      width: 10%;
    }

    i {
      background: white;
      border-radius: 20px;
      color: white;
      font-size: 10px;
      padding: 5px;
    }
  }

  @media only screen and (min-width: 430px) {
    p {
      color: #797979;
      font-size: 14px;
      padding: 0px 0px 0px 25px;
      text-align: left;
      width: 70%;
    }

    >div {
      height: inherit;
      width: 50%;
    }

    >div:nth-child(1) {
      align-items: center;
      display: flex;
      height: auto;
      justify-content: center;
      width: 10%;
    }

    i {
      background: white;
      border-radius: 20px;
      color: white;
      font-size: 10px;
      padding: 5px;
    }
  }
}

.reserveOption:hover {
  background-color: #DCE5F8;
  // border: 3px #2140D9;
  // border-style: groove;
}

.activeOption>div:first-child>i {
  background-color: #2140D9;
  border: 1px solid #8da0ff;
  color: white;
}

.deviceEnabledButtons {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px 15px;

  button {
    @media only screen and (max-width: 430px) {
      font-size: 10px;
      height: 40px;
    }

    @media only screen and (min-width: 431px) {
      font-size: 14px;
    }

    @media only screen and (max-height: 640px) {
      font-size: 10px;
      height: 37px;
    }

    border: 0px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-weight: 900;
    height: 45px;
    transition: all 1s;
  }

  button:hover {
    color: #ffea00;
    filter: drop-shadow(0px 0px 3px #ae7ae3);
  }

  .transactionButton {
    background: #1441e2;
    box-shadow: 2px 2px 2px #813fb8;
    width: 73%;
  }

  .transactionButton.active {
    background: #C44B4B !important;
    box-shadow: 2px 2px 2px #3DA225 !important;
  }

  .transactionButton.disabled {
    background: #A2A2A2 !important;
    cursor: not-allowed !important;
  }

  .transactionButton>.standby {
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50vh;
    position: relative;
    text-align: center;
    width: 100px;

    .dot {
      animation: wave 1.3s linear infinite;
      background: #303131;
      border-radius: 50%;
      display: inline-block;
      height: 12px;
      margin-right: 3px;
      width: 12px;

      &:nth-child(2) {
        animation-delay: -1.1s;
      }

      &:nth-child(3) {
        animation-delay: -0.9s;
      }
    }
  }

  @keyframes wave {

    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-15px);
    }
  }

  .endSessionButton {
    background: #C44B4B !important;
    box-shadow: 2px 2px 2px #3DA225 !important;
    width: 25%;
  }

  .sessionButtons {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
}

.meterContainer {
  align-content: center;
  align-items: center;
  background: whitesmoke;
  border-radius: 10px;
  color: gray;
  display: flex;
  flex-direction: row;
  font-family: Montserrat, sans-serif !important;
  font-size: 17px;
  height: 80px;
  justify-content: space-evenly;
  margin: 10px 0px;
  max-height: 90px;
  min-height: 60px;
  padding: 0px 10px;

  @media only screen and (max-width: 430px) {
    height: 60px;
  }

  >.divider {

    @media only screen and (max-width: 430px) {
      background-image: linear-gradient(to right, black 60%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-repeat: repeat-x;
      background-size: 9px;
      height: 1px;
      padding: 0px 5px;
      width: 10%;
    }

    @media only screen and (min-width: 431px) {
      background-image: linear-gradient(to right, black 60%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-repeat: repeat-x;
      background-size: 9px;
      height: 1px;
      padding: 0px 5px;
      width: 15%;
    }
  }

  >.status {
    align-items: center;
    background-color: #e2e2e2;
    border: 2px solid #6f6f6f;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    top: -2px;
    transition: all 1s;
    z-index: 1;

    >p {
      bottom: -26px;
      font-size: 8.5px;
      position: absolute;
      transition: all 1s;
    }

    >i {
      color: gray;
      transition: all 1s;
      font-size: 12px;
    }

    @media only screen and (max-width: 430px) {
      padding: 5px;

      >p {
        font-size: 7px;
      }

      >i {
        font-size: 8px;
      }
    }
  }

  >.status.active {
    animation: pulse-active 2s infinite;
    background-color: #D8EFD6;
    border: 2px solid green;
    color: green !important;

    >i {
      color: green !important;
    }
  }

  >.status.inactive {
    animation: pulse-inactive 2s infinite;
    background-color: #EFDDD6;
    border: 2px solid red;
    color: red !important;

    >i {
      color: red !important;
    }
  }

  >.status.preparing {
    animation: pulse-prep 2s infinite;
    background-color: #e2e2e2;
    border: 2px solid gray;
    color: gray !important;

    >i {
      color: gray !important;
    }
  }

  @keyframes rotate {
    50% {
      border-radius: 45% / 42% 38% 58% 49%;
    }

    100% {
      transform: translate(-50%, -50%) rotate(720deg);
    }
  }

  @keyframes pulse-prep {
    0% {
      box-shadow: 0 0 0 0 rgba(93, 93, 93, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(93, 93, 93, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(93, 93, 93, 0);
    }
  }

  @keyframes pulse-active {
    0% {
      box-shadow: 0 0 0 0 rgba(19, 224, 77, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(19, 224, 77, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(19, 224, 77, 0);
    }
  }

  @keyframes pulse-inactive {
    0% {
      box-shadow: 0 0 0 0 rgba(174, 11, 11, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(174, 11, 11, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(174, 11, 11, 0);
    }
  }


  // >div:nth-child(1)::before {
  //   content: "";
  //   position: absolute;
  //   width: inherit;
  //   height: inherit;
  //   transform: translate(-50%, -50%);
  //   border-radius: 50%;
  //   background-color: rgb(0, 0, 0);
  //   z-index: 10;
  // }

  .timer {
    font-family: Montserrat, sans-serif !important;

    @media only screen and (max-width: 430px) {
      font-size: 14px;
    }
  }

  .energyOutput {
    color: #87C758;
    font-size: 8px;
    text-align: center;

    >span {
      font-size: 20px;
    }

    @media only screen and (max-width: 430px) {
      font-size: 7px;

      >span {
        font-size: 18px;
      }

      >.status {
        p {
          font-size: 5px;
          bottom: -23px;
        }

        i {
          font-size: 6px;
        }
      }

      >.divider {
        background-size: 7px;
      }

      >.timer {
        font-size: 12px;
      }

      >.energyOutput {
        font-size: 6px;

        span {
          font-size: 16px;
        }
      }
    }
  }

  // >.timer:before,
  // .timer:after {
  //   content: "----";
  //   font-family: Montserrat, sans-serif !important;
  //   padding: 0px 10px;
  // }
}

.stationInvoiceContainer {
  color: #121F62;
  align-items: flex-start;
  display: none;
  flex-direction: column;
  margin: 10px 15px;
  opacity: 0;

  >.invoiceSection {
    margin: 0px 0px 5px 0px;
    width: 100%;

    h1 {
      @media only screen and (max-width: 430px) {
        font-size: 12px;
        font-weight: bold;
      }

      @media only screen and (min-width: 431px) {
        font-size: 14px;
        font-weight: bold;
      }
    }

    >.sectionDetails {
      @media only screen and (max-width: 430px) {
        font-size: 10px;
      }

      @media only screen and (min-width: 431px) {
        font-size: 13px;
      }


      font-size: 12px;

      >div {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 0px;
      }

      p:first-child {
        text-align: left !important;
      }

      p:last-child {
        text-align: right;
      }

      .divider {
        background-image: linear-gradient(to right, #cecece 80%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 9px;
        flex: auto;
        height: 1px;
        margin: 0px 10px;
        padding: 0px 5px;
      }

      #reservationFee {
        color: #C44B4B
      }
    }
  }

  @media only screen and (max-height: 640px) {
    margin: 2px 15px 10px 15px;

    >.invoiceSection {
      h1 {
        font-size: 9.5px;
      }

      >.sectionDetails {
        font-size: 7.5px;
      }
    }
  }
}

.reserveInputs {
  display: none;
}

.headerContainer {
  align-items: center;
  color: #813fb8;
  display: flex;
  font-size: 12px;
  height: 100%;
  justify-content: space-between;
  width: 100%
}

.mapboxgl-canvas {
  height: 100% !important;
  width: 100% !important
}

.directionsContainer {
  left: 10%;
  position: relative;
  top: 20%
}

.coordinates {
  background: rgba(0, 0, 0, .5);
  border-radius: 3px;
  bottom: 40px;
  color: #fff;
  display: none;
  font-size: 11px;
  left: 10px;
  line-height: 18px;
  margin: 0;
  padding: 5px 10px;
  position: absolute
}

.mapboxgl-ctrl-top-left {
  left: 1%;
  top: 12%
}

.map-overlay h2 {
  margin: 0 0 10px;
  padding: 10px
}

.mapboxgl-ctrl-geocoder {
  border-radius: 9px !important;
  font-size: 15px !important;
  line-height: 20px !important;
  margin: 20px !important;
  max-width: -webkit-fill-available !important;
  width: 100% !important
}

@-webkit-keyframes alertEnter {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }

  to {
    opacity: .9;
    transform: translateY(0)
  }
}

@keyframes alertEnter {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }

  to {
    opacity: .9;
    transform: translateY(0)
  }
}

.footerNav {
  background: #27312F;
  border: 1px solid #27312f;
  bottom: 0px;
  color: #8B8B8B;
  position: fixed;
  width: inherit;
  z-index: 999;
}

.footerNav .navButtonContainer {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.5em;
  justify-content: space-around;
  padding: 0px;
}

.navButtonContainer i {
  cursor: pointer;
  padding: 25px;
}

.navButtonContainer i:hover {
  color: #13AD67;
}

.locationSearch {
  margin: 0 auto;
  position: relative;
  top: 30px;
  width: 90%;
}

.locationSearch input {
  background: #2E3433 !important;
  color: white !important;
}

.locationSearch input::placeholder {
  color: #A8AAAA;
}

.mapboxgl-ctrl-geocoder input[type=text] {
  border: 0px solid #707070 !important;
  border-radius: 15px !important;
  box-shadow: 0px 0px 0px #ababab !important;
  font-size: 14px !important;
  height: 40px !important;
  padding: 5px 43px !important;
}

.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  fill: #A8AAAA !important;
  top: 15px;
  vertical-align: middle;
}

.mapboxgl-ctrl-geocoder--icon-search {
  height: 18px;
  left: 15px;
  position: absolute;
  top: 11px !important;
  width: 18px;
}

.mapboxgl-ctrl-geocoder {
  background-color: #2e3433;
  border-radius: 15px !important;
  border-radius: 4px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  min-width: 240px;
  position: relative;
  transition: width .25s, min-width .25s;
  width: 100%;
  z-index: 1;
}

.parentAppContainer {
  background-color: #23282d;
  width: 100%;
}


.profileDetailContainer {
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  overflow: clip;
}

.profileDetailContainer {
  .cancelButton {
    @media only screen and (min-width: 430px) {
      display: block !important;
      color: #b5b5b5;
      cursor: pointer;
      font-size: 22px;
      position: absolute;
      right: 10px;
      text-align: right;
      top: 10px;
      z-index: 10;
    }

    @media only screen and (max-width: 431px) {
      display: block !important;
      color: #b5b5b5;
      cursor: pointer;
      font-size: 22px;
      position: absolute;
      right: 12px;
      text-align: right;
      top: 8px;
      z-index: 10;
    }

    @media only screen and (max-height: 640px) {
      font-size: 19px;
    }
  }
}

.profileDetailContainer>.cancelButton:hover {
  color: #932e2e;
}

.parentMapContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: inherit;
  width: 100%;
  z-index: 1;
}

.parentProfileContainer {
  height: 100%;
  width: 100%;
}

.contentContainer {
  align-content: center;
  align-items: center;
  background-color: #2140d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 768px;
  min-height: inherit;
  position: relative;
  top: -1px;
}

.mapContainer {
  border: 1px solid #27312f;
  bottom: 0;
  max-width: 768px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: inherit;
}

.profileContainer {
  border: 1px solid #27312f;
  overflow-x: hidden;
  overflow-y: scroll;
}

.primaryInfoContainer {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
}

.profileInfo {
  align-content: center;
  align-items: flex-start;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 26%;
}

.profileImage {
  width: 100%;
}

.profileInfo img {
  background: #27312F;
  border: 1px solid #27312F;
  height: 120px;
  width: 95%;
}


.profileInfo h1 {
  font-size: 20px;
  margin: 10px 0px;
}

.profileInfo p {
  font-size: 0.85em;
  margin: 0px;
}

.profileButtons {
  align-content: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: -10px -30px;
  width: 55%;
}

.profileButtons button {
  background: #27312F;
  border: 0px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  height: 50px;
  width: 90%;
}

.profileButtons button:hover {
  background-color: #191e1d;
}

.profileInputs {
  align-content: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: -5px -30px;
  width: 55%;
}

.profileInput>div {
  position: relative;
  width: fit-content;
}

.profileInput p {
  color: #7B7B7B;
  font-size: 11px;
  margin: 5px 0px;
}

.profileInput>div>span {
  border-right: 1px solid #616464;
  bottom: 8px;
  color: #616464;
  left: 7px;
  padding: 0px 5px;
  position: absolute;
  width: 25px;

  i {
    font-weight: 300;
  }
}

.profileInputs input {
  background: #27312F;
  border: 1px solid #4f5965;
  border-radius: 10px;
  color: white;
  padding: 10px 50px;
}

.sliderContainer {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60%;
  justify-content: flex-start;
  overflow-x: scroll;
  width: auto;
}

.carouselContainer {
  margin-bottom: 60px;
  margin-top: 30px;
}

.profileAnalyticsContainer {
  background: #93a1b2;
  border-radius: 10px;
  height: 100%;
  margin-bottom: 15px;
  overflow: scroll;
  padding: 15px 30px;
  width: 400px;
}

.secondaryAnalyticsContainer {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  margin: 0 auto;
  margin-bottom: 100px;
  width: 90%;
}

.secondaryAnalytic {
  height: 200px;
}

.customerList {
  h1 {
    color: #413c3c;
    font-size: 17px;
    margin-bottom: 20px;
  }

  .customerRow {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px 0px;

    img {
      border-radius: 50px;
      height: 35px;
      width: 35px;
    }

    i {
      color: #363d50;
      cursor: pointer;
      font-size: 20px;
      left: 130px;
      position: relative;
      top: -3px;
    }

    i:hover {
      color: rgb(64, 81, 190)
    }

    .customerDetails {
      margin: 0px 20px;

      p:first-child {
        color: black;
        font-size: 13px;
        font-weight: 500;
        margin: 0px;
      }

      p {
        color: #565050;
        font-size: 10px;
        margin: 0px;
      }
    }
  }
}

.walletAnalytic {
  width: 100%;

  @media screen and (min-height: 700px) {
    height: 300px
  }

  @media screen and (max-height: 699px) {
    height: 280px
  }

  @media screen and (max-height: 640px) {
    height: 220px
  }
}

.walletButtons {
  align-content: space-around;
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 20px;
}

.topupInput {
  display: flex;
  flex-direction: column;

  >input {
    border: 3px solid #bbbbbb;
    border-radius: 6px;
    font-family: 'VT323';
    font-size: 18px;
    padding: 6px;
    text-align: center;

    @media only screen and (max-height: 640px) {
      font-size: 16px;
    }
  }

  >input:first-child {
    margin-bottom: 3px;
  }
}

.iconContainer {
  align-content: space-between;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  font-size: 17px;
  height: 30px;
  justify-content: center;
  margin: 0px 5px;
  width: 30px;

  @media only screen and (max-height: 640px) {
    height: 28px;
    width: 28px;
  }
}

.topupDefaults {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 0px 0px 0px;
  width: 124%;

  >div {
    background: #f0fff4;
    border: 1px solid #40d28f;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    padding: 6px;
    width: 30px;
    text-align: center;

    @media only screen and (max-height: 640px) {
      font-size: 10px;
    }
  }

  >div:hover {
    background: #bad9c2;
  }
}

.iconContainer:hover {
  background-color: #eaeaea;
  color: #40d28f !important;
}

.iconContainer.deposit {
  border: 3px solid #3FD28F;
  color: #3FD28F;
}

.iconContainer.pay {
  border: 3px solid #4F92CB;
  color: #4F92CB;
}

.iconContainer.qr {
  border: 3px solid #D2943F;
  color: #D2943F;
}

.walletButtons>div>p {
  color: white;
  font-size: 10px;
  margin: 6px 0px;
  text-align: center;
}

.walletBalance {
  height: 0px;
  left: 0;
  position: relative;
  text-align: center;
  top: 37%;

  @media only screen and (max-width: 430px) {
    p {
      color: #a8b7fe;
      font-family: 'VT323' !important;
      font-size: 18px;
      margin: 0px 0px;
    }

    h1 {
      color: #2040d9;
      font-size: 52px;
      margin: 0px;
    }
  }

  @media only screen and (min-width: 431px) {
    p {
      color: #a8b7fe;
      font-family: 'VT323' !important;
      font-size: 20px;
      margin: 0px 0px;
    }

    h1 {
      color: #2040d9;
      font-size: 65px;
      margin: 0px;
    }
  }

  @media only screen and (max-height: 640px) {
    top: 34%;
  }
}

.walletButtons>div {
  p {
    color: #40d290;
    font-weight: bold;
  }
}

.walletButtons {
  .addTextField {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 110%;

    p {
      color: #db8181;
      font-weight: normal;
      font-size: 15px;
      font-family: "VT323";
      margin: 5px 0px 5px 0px;
    }
  }
}

.transactionHistory {
  border-top: 1px solid #707070;
  margin: 20px 6px;
  overflow: auto;
  height: calc(80vh - 440px);

  @media only screen and (max-height: 640px) {
    height: calc(96vh - 440px);
  }
}

.transactionHistory>h1 {
  color: #A2A2A2;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0px;

  @media only screen and (max-height: 640px) {
    font-size: 12px;
  }
}

.transactionTable {
  height: -webkit-fill-available;
  height: -moz-available;
}

.transactionTable .row {
  align-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  opacity: 0.8;
}

.transactionTable .row:hover {
  border: 2px solid #3fd28e;
  opacity: 1;
}

.transactionTable .row .category {
  color: #004982;
  margin: 0px 3vw;
  text-align: left;
  width: 20vw;

  h1 {
    font-size: 12px;
    margin: 5px 0px;
  }

  p {
    font-size: 10px;
    margin: 5px 0px;
  }

  @media only screen and (max-height: 640px) {
    h1 {
      font-size: 10px;
    }

    p {
      font-size: 8px;
    }
  }
}

.transactionTable .row .details {
  color: #A2A2A2;
  margin: 0px 0px;
  overflow: hidden;
  text-align: left;
  width: 25vw;

  h1 {
    font-size: 12px;
    margin: 5px 0px;
    white-space: nowrap;
  }

  p {
    color: #8a8a8a;
    font-size: 10px;
    margin: 5px 0px;
    white-space: nowrap;
  }

  @media only screen and (max-height: 640px) {
    h1 {
      font-size: 10px;
    }

    p {
      font-size: 8px;
    }
  }
}

.transactionTable .row .amount {
  margin: 0px 3vw;
  text-align: left;
  width: 63px;

  h1 {
    font-size: 15px;
    margin: 5px 0px;
  }

  p {
    font-size: 10px;
    margin: 5px 0px;
  }

  @media only screen and (max-height: 640px) {
    h1 {
      font-size: 13px;
    }

    p {
      font-size: 8px;
    }
  }
}

.amount.deduct {
  color: #e14343;

  p {
    color: #cd6b6b;
  }
}

.amount.topup {
  color: #2ca669;

  p {
    color: #3cce7c;
  }
}

.transactionTable .row:nth-child(odd) {
  background-color: #EFEFEF;
}

.userSettingsContainer {
  max-height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 5px;

  h3 {
    color: rgb(18, 31, 98);
    margin-bottom: 12px;
    margin-top: 12px;
  }

  p {
    color: #7B7B7B;
    font-size: 13px;
    margin: 0.4rem;
  }

  #divSettingSaveContainer {
    justify-content: space-between;

    #btnSaveChanges {
      width: 76%;

      span {
        font-family: Arial;
      }
    }

    #btnLogout {
      width: 22%;
      background-color: #152c9b;
      font-family: Arial;
    }

    @media only screen and (max-width: 430px) {
      font-size: 15px;

      #btnSaveChanges {
        width: 68%;
      }

      #btnLogout {
        width: 30%;
      }
    }
  }

  #divOTPButtonContainer {
    justify-content: space-between;

    #otp {
      width: 60%;
      height: 42px;
      text-align: center;
      padding-left: unset;
    }

    #btnVerifyOTP {
      width: 36%;
    }
  }

  @media only screen and (max-height: 640px) {
    h3 {
      color: rgb(18, 31, 98);
      margin-bottom: 8px;
      margin-top: 8px;
      font-size: 14px;
    }

    p {
      color: #7B7B7B;
      font-size: 8px;
      margin: 0.4rem;
    }

    input {
      font-size: 10px;
      height: 20px;
    }
  }
}

.stationSettingsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: flex-start;
  overflow: scroll;
}

.settings {
  align-content: center;
  align-items: flex-start;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33%;

  img {
    border-radius: 10px;
    border-radius: 0px;
    height: 100%;
    opacity: 0.7;
  }

  i {
    background: #3A4341;
    border: 1px solid #606060;
    border-radius: 30px;
    color: #b5b5b5;
    cursor: pointer;
    font-size: 8px;
    margin: 5px;
    padding: 6px;
    position: absolute;
    z-index: 100;
  }
}

.chartContainer {
  height: inherit;

  >.chart {
    border: 1px solid #00000000;
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
    width: 250px !important;
  }

  >.chart-wallet {
    border: 1px solid #00000000;
    border-radius: 10px;
    height: inherit;
    overflow: hidden;

    @media only screen and (max-height: 640px) {
      height: 210px;
    }
  }
}

.stationDetails {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 40px 10px 0px 10px;
  margin-top: 40px;
}

.stationProfile {
  background: #3D4B48;
  height: 100px;
  width: 20%;
}

.stationProfile img {
  height: 100%;
  padding: 11px;
  width: 100%;
}

.stationMap {
  background: #2D3E46;
  height: 0%;
  position: absolute;
  top: 0px;
  width: inherit;
  z-index: 998;
}

.parkingSpotsContainer {
  align-items: flex-start;
  display: none;
  grid-template-columns: auto auto;
  height: 90%;
  justify-items: center;
  margin: 0px;
  overflow: scroll;
  padding-top: 38px;
}

.parkingSpot {
  --b: 5px;
  /* thickness of the border */
  --c: rgb(64, 153, 70);
  /* color of the border */
  --w: 20px;
  align-content: flex-start;
  align-items: center;
  align-items: flex-start;
  background:
    conic-gradient(from 90deg at top var(--b) left var(--b), var(--g)) 0 0,
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100% 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: var(--w) var(--w);
  /* width of border */


  border: var(--b) solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  display: flex;
  font-size: 30px;
  height: 100px;
  justify-content: center;
  justify-content: flex-start;
  line-height: 90px;
  margin: 15px;
  padding: 13px;
  width: 200px;
  /* space for the border */
  --g: #0000 90deg, var(--c) 0;
}

.parkingSpot:hover {
  --c: #EAF477;

  p {
    color: #EAF477 !important;
  }
}

.parkingSpot.active {
  --c: #EAF477;

  p {
    color: #EAF477 !important;
  }
}

.parkingSpot.inactive {
  --c: rgb(150, 150, 150);
}

.parkingSpot.inactive {
  --c: rgb(150, 150, 150);
  cursor: initial;

  p {
    color: rgb(150, 150, 150) !important;
  }
}


.parkingSpot.inactive i {
  color: rgb(150, 150, 150);
  font-size: 13px;
}

.parkingSpot i {
  color: green;
  font-size: 13px;
}

.parkingSpot div {
  padding: 13px;
  position: relative;
  top: -65px;
}

.parkingSpot .lotNumber {
  color: gray;
  font-size: 11px;
  height: 0px;
}

.parkingSpot>div>p {
  margin-bottom: 20px;
}

.parkingSpot .connectorType {
  color: gray;
  font-size: 14px;
  font-weight: 800;
  height: 0px;
}

.parkingSpot .wattage {
  color: gray;
  font-size: 12px;
  height: 0px;
}

.preferencesContainer {
  margin: 0px 40px;

  h1 {
    color: #D6D6D6;
    font-size: 20px;
    margin-top: 40px;
  }
}

.inputSection {
  div {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  i {
    color: #B1B1B1;
    font-size: 13px;
    margin-right: 14px;
  }

  p {
    color: #B1B1B1;
    font-size: 13px;
    margin: 8px 0px;
  }

  select {
    background: #3a434e;
    border: 0px solid;
    color: #000312;
    font-weight: bold;
  }


  .switch {
    --line: #E8EBFB;
    --dot: #2bb349;
    --circle: #d44040;
    --background: #3a434e;
    --duration: .3s;
    --text: #9EA0BE;
    --shadow: 0 1px 3px rgba(0, 9, 61, 0.08);
    cursor: pointer;
    position: relative;

    &:before {
      background: var(--background);
      border-radius: 16px;
      box-shadow: var(--shadow);
      content: '';
      height: 32px;
      left: 0;
      position: absolute;
      top: 0;
      width: 60px;
      z-index: -9999;
    }

    input {
      display: none;

      &+div {
        position: relative;

        &:before,
        &:after {
          --s: 1;
          background: var(--line);
          content: '';
          height: 3px;
          position: absolute;
          top: -3px;
          transform: scaleX(var(--s));
          transition: transform var(--duration) ease;
          width: 22px;
        }

        &:before {
          --s: 0;
          border-radius: 2px 0 0 2px;
          left: 4px;
          transform-origin: 0 50%;
        }

        &:after {
          border-radius: 0 2px 2px 0;
          left: 26px;
          transform-origin: 100% 50%;
        }

        span {
          color: var(--text);
          line-height: 28px;
          padding-left: 60px;

          &:before {
            --x: 0;
            --b: var(--circle);
            --s: 4px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 var(--s) var(--b);
            content: '';
            height: 18px;
            left: 4px;
            position: absolute;
            top: -10px;
            transform: translateX(var(--x));
            transition: box-shadow var(--duration) ease, transform var(--duration) ease;
            width: 18px;
          }

          &:not(:empty) {
            padding-left: 68px;
          }
        }
      }

      &:checked {
        &+div {
          &:before {
            --s: 1;
          }

          &:after {
            --s: 0;
          }

          span {
            &:before {
              --x: 28px;
              --s: 12px;
              --b: var(--dot);
            }
          }
        }
      }
    }
  }
}

.settingsButtons {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 35px 25px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  div {
    align-content: center;
    align-items: center;
    background: #27312f;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    height: 100px;
    justify-content: center;
    margin: 6px;
    padding: 15px;
    text-align: center;
    width: 100px;

    p {
      font-size: 10px;
      margin: 8px 0px;
    }
  }

  div:hover {
    background-color: #191e1d;
  }
}

.manageStationsContainer {
  margin: 100px 40px;

  >i {
    color: white;
    cursor: pointer;
    font-size: 30px;
    left: 40px;
    position: absolute;
    top: 40px;
  }

  .stationsContainer {
    margin-bottom: 40px;

    .stationsHeader {
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: space-between;

      h1 {
        color: #D6D6D6;
        font-size: 23px;
      }

      div {
        button {
          background-color: #00000000;
          border: 1px solid white;
          border-radius: 12px;
          color: white;
          cursor: pointer;
          font-size: 11px;
          margin-left: 10px;
          padding: 5px 20px;
        }

        button:last-child {
          border-radius: 25px;
          padding: 5px 8px;
        }

        button:hover {
          background-color: #191e1d;
        }
      }
    }
  }

  .stationsList {
    >div {
      background: #27312F;
      border-radius: 15px;
      cursor: pointer;
      display: flex;
      margin: 10px 0px;
      width: 100%;

      .stationStatus {
        border-radius: 15px 0px 0px 15px;
        display: flex;
        left: 0px;
        position: relative;
        text-align: center;
        writing-mode: horizontal-tb;

        p {
          color: white;
          font-size: 12px;
          margin: 5px;
          /* text-orientation: inherit; */
          transform: rotate(180deg);
          writing-mode: vertical-rl;
        }
      }

      .online {
        background: #20c779;
      }

      .offline {
        background: #BC873F;
      }

      .unavailable {
        background: #818181;
      }

      .stationDetail {
        align-items: flex-start;
        color: #c9c9c9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 15px 12px;
        width: 66%;

        >p {
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 11px;
          margin-top: 0px;
        }

        >div {
          display: flex;
          flex-direction: row;
          font-size: 11px;

          p {
            margin: 0px;
          }

          p:first-child {
            width: 58%;
          }

          p:last-child {
            width: 20%;
          }
        }
      }

      .stationSpecs {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          font-size: 11px;
          margin: 3px 0px;
        }

        p:nth-of-type(1) {
          color: #707070;
        }

        p:nth-of-type(2) {
          color: #59C37D;
        }

        p:nth-of-type(3) {
          color: #B4B4B4;
        }
      }

      >i {
        color: #cecece;
        cursor: pointer;
        font-size: 12px;
        left: 13px;
        position: relative;
        top: 9px;
      }
    }

    >div:hover {
      background-color: #191e1d;
      border: 1px solid green;
    }
  }
}

#modal {
  height: 75vh !important;
  width: auto !important;
}

.addVehicleCard {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: auto;
  max-width: 230px;
  position: relative;
  text-align: center;
  width: 70%;

  [id$='Container'] {
    position: relative;
  }

  img {
    height: 69px;
    width: 69px;
    margin-left: auto;
    margin-right: auto;
  }

  h3 {
    font-size: 18px;
    margin-top: unset;
    margin-bottom: 7px;
    color: grey
  }

  p {
    font-size: 9px;
    margin: unset;
  }

  #divFormContainer {
    margin: 15px 5px;
    display: flex;
    justify-content: center;
  }

  #btnAddVehicle {
    width: 0%;
    border-radius: 0px 10px 10px 0px;
    display: none;
    height: 35px;
  }

  @media only screen and (max-height: 640px) {
    img {
      height: 60px;
      width: 60px;
    }

    h3 {
      font-size: 15px;
    }

    p {
      font-size: 7px;
    }
  }
}

.customInput {
  background-color: #FBFBFB;
  border: 0.5px solid #bbbbbb;
  border-radius: 12px;
  font-size: 14px;
  height: 25px;
  padding-left: 5%;
  width: 93%;
}

.card {
  border-radius: 10px;
  box-shadow: 0 .3rem 0.4rem rgba(0, 0, 0, .15);
  display: flex;
  height: 238px;
  margin-bottom: 35px;
  padding: 5px 10px 0px 10px;
  max-width: 90%;

  @media only screen and (max-height: 640px) {
    height: 165px;
    margin-bottom: 22px;
  }
}

.row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.settingsButton {
  background-color: #2140d9;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  height: 45px;
  width: 75px;
  font-weight: 900;

  @media only screen and (max-width: 430px) {
    font-size: 15px;
  }

  @media only screen and (max-height: 640px) {
    height: 33px;
    font-size: 12px;
  }
}

.settingsButton:hover,
.settingsButton:active {
  background: #1e3ca9;
  color: #ffee20;
}

.settingsButton[disabled] {
  background-color: #A2A2A2;
  cursor: not-allowed;
}

#divAutocompleteContainer {
  border: 1px solid rgb(128, 128, 128);
  border-radius: 10px;
  height: 33px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.vehicleCard {
  flex-direction: column;

  i {
    font-size: 18px;
    color: #b1b1b1;
    cursor: pointer;
    padding: 3px 3px 0px 0px;
  }

  i:hover {
    color: #7a7a7a;
  }

  .vehicleNumber {
    border-radius: 4px;
    font-size: 11px;
    margin: 0px 5px 10px 5px;
    padding: 2px;
    border: 1px solid #d6d6d6;
    color: #939393;
    text-align: center;
  }

  >div {
    display: flex;
    flex-wrap: nowrap;
  }

  h4 {
    margin: 0px;
    padding-left: 6px;
    width: 90%;
  }

  img {
    height: 100%;
    margin: auto;
    object-fit: contain;
    width: 100%;
  }

  .col {
    background-color: #fafafa;
    border-radius: 10px;
    padding: 5px 0px 0px 0px !important;
    text-align: center;
    width: 70px;

    b {
      color: #212529;
      font-size: 14px;
    }

    p {
      font-size: 9px;
      margin: 2px;
    }
  }

  div:nth-child(1) {
    padding: 5px 0;
  }

  div:nth-child(3) {
    justify-content: space-between;
  }

  div:nth-child(4) {
    height: 60%;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media only screen and (max-height: 640px) {
    i {
      font-size: 14px;
    }

    h4 {
      font-size: 13px;
    }

    .vehicleNumber {
      font-size: 7px;
      margin: 0px 5px 5px 5px;
    }

    .col {
      background-color: #fafafa;
      border-radius: 10px;
      padding: 5px 0px 0px 0px !important;
      text-align: center;
      width: 70px;

      b {
        color: #212529;
        font-size: 9px;
      }

      p {
        font-size: 7px;
        margin: 6px;
      }
    }

    div:nth-child(4) {
      height: 40%;
      overflow: hidden;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

#agreement {
  label {
    color: rgba(255, 255, 255, 0.5098039216);
    font-family: "VT323";
    font-size: 20px;
    margin: 0px 0px 15px 0px;
    padding: 0px;
    text-align: center;
    width: 302px;

    a {
      text-decoration: none;
      color: white;
    }
  }
}

.deviceNavigators {
  justify-content: space-evenly;
  color: #2140D9;
  padding: 10px 10px;
  opacity: 1;
  z-index: 11;
  bottom: 270px;
  height: 23px;

  i {
    cursor: pointer;
  }

  i:active,
  i:hover {
    color: rgb(18, 31, 98);
  }

  >div:nth-child(2) {
    text-align: center;
  }

  >div:nth-child(3) {
    text-align: right;
  }

  .chargeNavs {
    width: 90%;
  }

  .chargeNavs>p:nth-child(1) {
    margin: 3px;
    font-weight: 600;
  }
}

#divOTPInput {
  width: 250px;

  p {
    width: 100%;
  }

  span {
    float: right;
    color: white;
    cursor: pointer;
    margin: 5px 0;
  }

  input {
    margin: 10px 0 10px 10px;
    width: 200px;
    padding: 15px 0;
  }
}

#divConfirmOrDeny {
  padding: 0 0 0 10px;

  h3 {
    color: #004982;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    margin: 0px 0px 10px 5px;

    @media only screen and (max-width: 430px) {
      font-size: 13px;
    }
  }

  .info {
    color: #828282;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: whitesmoke;
    border-radius: 10px;
    padding: 0 10px;

    p {
      width: 90%;
      font-size: 12px;
      margin-left: 10px;

      @media only screen and (max-width: 430px) {
        font-size: 10px;
      }
    }

    i {
      color: #828282;
      font-size: 18px;
    }

    span {
      color: #626262;
      font-weight: 600;
    }
  }

  .deviceButtons {
    margin: 15px 0 15px 0;
  }

  .deviceButtons>button:first-child {
    background: #1440e1;
    width: 76%;
  }

  .deviceButtons>button:last-child {
    background: #828282;
    width: 22%;
  }

  @media only screen and (max-height: 640px) {
    h3 {
      font-size: 11px;
    }

    .info {
      i {
        font-size: 16px;
      }

      p {
        font-size: 8px;
      }
    }

    .deviceButtons {
      margin: 15px 0px 5px 0px;
    }
  }
}

#divEndSession {
  padding: 0 0 0 10px;

  h3 {
    color: #004982;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    margin: 0px 0px 10px 5px;
  }

  .info {
    color: #5a5a5a;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #fde9e7;
    border-radius: 10px;
    padding: 0 10px;

    p {
      width: 90%;
      font-size: 12px;
      margin-left: 10px;
    }

    i {
      color: #C44B4B;
      font-size: 18px;
    }

    span {
      color: #626262;
      font-weight: 600;
    }
  }

  .deviceButtons {
    margin: 15px 0 15px 0;
  }

  .deviceButtons>button:first-child {
    background: #C44B4B;
    width: 30%;
  }

  .deviceButtons>button:last-child {
    background: #828282;
    width: 68%;
  }

  @media only screen and (max-height: 640px) {
    h3 {
      font-size: 12px;
    }

    .info {
      i {
        font-size: 16px;
      }

      p {
        font-size: 10px;
      }
    }

    .deviceButtons {
      margin: 15px 0px 5px 0px;
    }
  }
}

#divCancelSession {
  padding: 0 0 0 10px;
  display: none;

  h3 {
    color: #004982;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    margin: 0px 0px 10px 5px;
  }

  .info {
    color: #5a5a5a;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #fde9e7;
    border-radius: 10px;
    padding: 0 10px;

    p {
      width: 90%;
      font-size: 12px;
      margin-left: 10px;
    }

    i {
      color: #C44B4B;
      font-size: 18px;
    }

    span {
      color: #626262;
      font-weight: 600;
    }
  }

  .deviceButtons {
    margin: 15px 0 15px 0;
  }

  .deviceButtons>button:first-child {
    background: #C44B4B;
    width: 30%;
  }

  .deviceButtons>button:last-child {
    background: #828282;
    width: 68%;
  }

  @media only screen and (max-height: 640px) {
    h3 {
      font-size: 12px;
    }

    .info {
      i {
        font-size: 16px;
      }

      p {
        font-size: 10px;
      }
    }

    .deviceButtons {
      margin: 15px 0px 5px 0px;
    }
  }
}

#divDirections {
  max-height: 250px;
  overflow-y: scroll;
  padding: 0 10px;

  .fa-up-long {
    margin-right: 18px;
  }

  .fa-left-long {
    margin-right: 10px;
  }

  .fa-right-long {
    margin-right: 10px;
  }

  img {
    margin: auto;
    width: 18px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: invert(23%) sepia(14%) saturate(4965%) hue-rotate(186deg) brightness(92%) contrast(91%);
  }

  .directionsBackButton {
    font-size: 21px;
    color: #004982;
    font-weight: 700;
    margin: 10px 0;

    i {
      color: #004982;
      font-size: 20px;
      cursor: pointer;
      margin: 0 10px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      float: right;
      margin-right: 5px;
    }
  }

  >div>p {
    font-size: 18px;
    padding: 0 0 0 45px;
  }
}

.refreshButton {
  background: #ffee21;
  border: 2px solid #2040d9;
  color: #203fd9;
  margin: 15px 6px 8px 5px;
  width: 50px !important;
}

#resendOTP {
  margin: 10px;

  span {
    color: white;
    cursor: pointer;
  }
}

.availableConnector {
  border: 1px solid #408e30;

  p {
    color: #408e30;

  }

  img {
    filter: invert(61%) sepia(19%) saturate(3057%) hue-rotate(68deg) brightness(71%) contrast(68%);
  }
}

.unavailableConnector {
  border: 1px solid #616161;

  p {
    color: #616161;
  }

  img {
    filter: invert(37%) sepia(0%) saturate(0%) hue-rotate(253deg) brightness(94%) contrast(78%);
  }
}

.faultedConnector {
  border: 1px solid #d92138;

  p {
    color: #d92138;
  }

  img {
    filter: invert(29%) sepia(98%) saturate(5647%) hue-rotate(342deg) brightness(88%) contrast(92%);
  }
}

.showInvoice {
  position: absolute;
  top: 0%;
  left: auto;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  width: 95%;
  color: #121F62;

  p {
    font-family: Montserrat, sans-serif;
  }

}

// .invoiceDetails {
//   align-content: center;
//   align-items: center;
//   background: whitesmoke;
//   border-radius: 10px;
//   color: gray;
//   display: flex;
//   flex-direction: row;
//   font-family: Montserrat, sans-serif !important;
//   font-size: 17px;
//   height: 80px;
//   justify-content: space-evenly;
//   margin: 10px 0px;
//   max-height: 90px;
//   min-height: 60px;
//   padding: 0px 10px;

//   @media only screen and (max-width: 430px) {
//     height: 60px;
//   }

//   >.divider {

//     @media only screen and (max-width: 430px) {
//       background-image: linear-gradient(to right, black 60%, rgba(255, 255, 255, 0) 0%);
//       background-position: bottom;
//       background-repeat: repeat-x;
//       background-size: 9px;
//       height: 1px;
//       padding: 0px 5px;
//       width: 10%;
//     }

//     @media only screen and (min-width: 431px) {
//       background-image: linear-gradient(to right, black 60%, rgba(255, 255, 255, 0) 0%);
//       background-position: bottom;
//       background-repeat: repeat-x;
//       background-size: 9px;
//       height: 1px;
//       padding: 0px 5px;
//       width: 15%;
//     }
//   }

//   >.status {
//     align-items: center;
//     background-color: #e2e2e2;
//     border: 2px solid #6f6f6f;
//     border-radius: 60px;
//     display: flex;
//     justify-content: center;
//     padding: 10px;
//     position: relative;
//     text-align: center;
//     text-transform: uppercase;
//     top: -2px;
//     transition: all 1s;
//     z-index: 1;

//     >p {
//       bottom: -26px;
//       font-size: 8.5px;
//       position: absolute;
//       transition: all 1s;
//     }

//     >i {
//       color: gray;
//       transition: all 1s;
//       font-size: 12px;
//     }
//   }
// }

// .duration {
//   align-items: center;
//   padding-top: 2px;
//   display: flex;
//   justify-content: center;
//   padding: 10px;
//   position: relative;
//   text-align: center;
//   top: -2px;
//   transition: all 1s;
//   z-index: 1;

//   >p {
//     bottom: -20px;
//     font-size: 8.5px;
//     position: absolute;
//     transition: all 1s;
//   }
// }


// .energy {
//   color: #87C758;
//   font-size: 8px;
//   text-align: center;
//   margin-top: 15px;
//   >span {
//     font-size: 20px;
//   }

//   @media only screen and (max-width: 430px) {
//     font-size: 7px;

//     >span {
//       font-size: 18px;
//     }
//   }
//   #energyLabel{
//     margin-top: 11px;
//     display: block;
//     font-size: 8.5px;
//   }
// }

.invoiceDetails {
  align-content: center;
  align-items: center;
  background: whitesmoke;
  border-radius: 10px;
  color: gray;
  display: flex;
  flex-direction: row;
  font-family: Montserrat, sans-serif !important;
  font-size: 17px;
  height: 80px;
  justify-content: space-evenly;
  margin: 10px 0px;
  max-height: 90px;
  min-height: 60px;
  padding: 0px 10px;

  @media only screen and (max-width: 430px) {
    height: 60px;
  }

  >.divider {

    @media only screen and (max-width: 430px) {
      background-image: linear-gradient(to right, black 60%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-repeat: repeat-x;
      background-size: 9px;
      height: 1px;
      padding: 0px 5px;
      width: 10%;
    }

    @media only screen and (min-width: 431px) {
      background-image: linear-gradient(to right, black 60%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-repeat: repeat-x;
      background-size: 9px;
      height: 1px;
      padding: 0px 5px;
      width: 15%;
    }
  }

  >.status {
    align-items: center;
    background-color: #e2e2e2;
    border: 2px solid #6f6f6f;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    top: -2px;
    transition: all 1s;
    z-index: 1;

    >p {
      bottom: -26px;
      font-size: 8.5px;
      position: absolute;
      transition: all 1s;
    }

    >i {
      color: gray;
      transition: all 1s;
      font-size: 12px;
    }

    @media only screen and (max-width: 430px) {
      padding: 5px;

      >p {
        font-size: 7px;
      }

      >i {
        font-size: 8px;
      }
    }
  }

  >.status.active {
    animation: pulse-active 2s infinite;
    background-color: #D8EFD6;
    border: 2px solid green;
    color: green !important;

    >i {
      color: green !important;
    }
  }

  >.status.inactive {
    animation: pulse-inactive 2s infinite;
    background-color: #EFDDD6;
    border: 2px solid red;
    color: red !important;

    >i {
      color: red !important;
    }
  }

  >.status.preparing {
    animation: pulse-prep 2s infinite;
    background-color: #e2e2e2;
    border: 2px solid gray;
    color: gray !important;

    >i {
      color: gray !important;
    }
  }

  @keyframes rotate {
    50% {
      border-radius: 45% / 42% 38% 58% 49%;
    }

    100% {
      transform: translate(-50%, -50%) rotate(720deg);
    }
  }

  @keyframes pulse-prep {
    0% {
      box-shadow: 0 0 0 0 rgba(93, 93, 93, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(93, 93, 93, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(93, 93, 93, 0);
    }
  }

  @keyframes pulse-active {
    0% {
      box-shadow: 0 0 0 0 rgba(19, 224, 77, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(19, 224, 77, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(19, 224, 77, 0);
    }
  }

  @keyframes pulse-inactive {
    0% {
      box-shadow: 0 0 0 0 rgba(174, 11, 11, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(174, 11, 11, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(174, 11, 11, 0);
    }
  }


  // >div:nth-child(1)::before {
  //   content: "";
  //   position: absolute;
  //   width: inherit;
  //   height: inherit;
  //   transform: translate(-50%, -50%);
  //   border-radius: 50%;
  //   background-color: rgb(0, 0, 0);
  //   z-index: 10;
  // }

  .duration {
    font-family: Montserrat, sans-serif !important;

    @media only screen and (max-width: 430px) {
      font-size: 14px;
    }
  }

  .energy {
    color: #87C758;
    font-size: 8px;
    text-align: center;

    >span {
      font-size: 20px;
    }

    @media only screen and (max-width: 430px) {
      font-size: 7px;

      >span {
        font-size: 18px;
      }
    }
  }

  // >.timer:before,
  // .timer:after {
  //   content: "----";
  //   font-family: Montserrat, sans-serif !important;
  //   padding: 0px 10px;
  // }
}


.walletInvoiceContainer {
  color: #121F62;
  align-items: flex-start;
  display: none;
  flex-direction: column;
  margin: 10px 15px;
  opacity: 0;

  .invoiceInfo {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: auto;

    .invoiceHead {
      width: -webkit-fill-available;
      display: flex;
      flex-direction: row;
      color: #121f62;
      justify-content: space-between;
    }

    >div {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    @media only screen and (min-width: 430px) {
      h1 {
        color: #121f62;
        font-size: 14px;
        margin: 8px 0px 0px 0px;
      }

      i {
        color: #b5b5b5;
        float: right;
        font-size: 22px;
        margin: 3px 0px 0px 0px;
        cursor: pointer;
      }

      i:hover {
        color: #932e2e;
      }


      >.invoiceAddress {
        color: #a1a1a1;
        font-size: 12px;
        margin: 5px 0px;
      }
    }

    @media only screen and (max-width: 430px) {
      h1 {
        color: #121f62;
        font-size: 12px;
        font-weight: bold;
        margin: 8px 0px 0px 0px;
      }

      i {
        color: #b5b5b5;
        float: right;
        font-size: 22px;
        margin: 8px 0px;
      }

      >.invoiceAddress {
        color: #a1a1a1;
        font-size: 9px;
        margin: 5px 0px;
      }
    }

    @media only screen and (max-height: 640px) {
      h1 {
        color: #121f62;
        font-size: 10px;
        font-weight: bold;
        margin: 8px 0px 0px 0px;
      }

      i {
        color: #b5b5b5;
        float: right;
        font-size: 20px;
        margin: 8px 0px;
      }

      >.invoiceAddress {
        color: #a1a1a1;
        font-size: 7px;
        margin: 5px 0px;
      }
    }
  }

  >.invoiceWalletSection {
    margin: 0px 0px 5px 0px;
    width: 100%;

    h1 {
      @media only screen and (max-width: 430px) {
        font-family: Montserrat, sans-serif;
        font-size: 12px;
        font-weight: bold;
      }

      @media only screen and (min-width: 431px) {
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: bold;
      }

      @media only screen and (max-height: 640px) {
        font-family: Montserrat, sans-serif;
        font-size: 9.5px;
        font-weight: bold;
      }
    }

    >.invoiceSectionDetails {
      @media only screen and (max-width: 430px) {
        font-size: 10px;
      }

      @media only screen and (min-width: 431px) {
        font-size: 13px;
      }

      @media only screen and (max-height: 640px) {
        font-size: 7.5px;
      }

      font-size: 12px;

      >div {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 0px;
      }

      p:first-child {
        text-align: left !important;
      }

      p:last-child {
        text-align: right;
      }

      .invoiceDivider {
        background-image: linear-gradient(to right, #cecece 80%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 9px;
        flex: auto;
        height: 1px;
        margin: 0px 10px;
        padding: 0px 5px;
      }

      #chargeReservationFee {
        color: #C44B4B;
      }

      #cgst {
        color: #C44B4B;
      }

      #sgst {
        color: #C44B4B;
      }

      #paymentGatewayFee {
        color: #C44B4B;
      }
    }
  }

  .taxInfo {
    p {
      font-size: 11px;

      @media only screen and (max-width: 430px) {
        font-size: 9px;
      }
    }

    span {
      color: #C44B4B;
      font-size: larger;
    }
  }
}

.contactUs {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 430px) {
    flex-direction: column;
  }

  .header {
    h1 {
      margin: 5px 0;
      color: #121f62;
      font-size: 24px;
    }

    p {
      margin: 2px;
      color: #7b7b7b;
      font-size: 13px;
    }
  }

  .sub-header {
    h1 {
      margin: 18px 0px 9px 0px;
      color: #121f62;
      font-size: 15px;
      font-weight: bold;
    }

    p {
      margin: 2px;
      color: #7b7b7b;
      font-size: 13px;
    }
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    h4 {
      margin: 16px;
      color: #283fd1;
    }

    p {
      margin: 2px;
      color: #4e4e4f;
      width: 80%;
      font-size: 12px;
    }

    a {
      margin: 2px;
      color: #4e4e4f;
      width: 80%;
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
    }

    i {
      width: 5%;
      color: #121f62;
      font-size: 20px;
      margin-right: 15px;
    }

    .detailContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin: 8px 0;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    justify-content: center;
    width: fit-content;
    align-self: center;

    .socialMedia {
      width: 100%;

      a {
        text-decoration: none;
        width: 18px;
        background-color: #283fd1;
        border: none;
        color: #ffffff;
        margin: 0 5px;
        padding: 16px;
        border-radius: 50%;
        font-size: 16px;
        cursor: pointer;
        text-align: center;
      }

      a:hover {
        background-color: #1d2e9c;
      }
    }
  }

  .card {
    height: auto;
    flex-direction: column;
    /* width: 40%; */
    padding: 12px 16px;
    margin-bottom: 0;
    /* min-height: 260px; */
    box-shadow: none;

    @media only screen and (max-width: 430px) {
      width: 85%;
      margin: 15px;
    }
  }

  .cancelButton {
    position: absolute;
    right: 10px;
    top: 12px;
    color: gray;
    cursor: pointer;
    font-size: 20px;
  }

  @media only screen and (max-height: 640px) {
    .header {
      h1 {
        font-size: 21px;
      }
    }

    .sub-header {
      h1 {
        font-size: 13px;
      }
    }

    .body {
      p {
        font-size: 10px;
      }

      a {
        font-size: 10px;
      }

      i {
        font-size: 17px;
      }

      .card {
        padding: 0px 16px;
        margin-bottom: 0;
        /* min-height: 260px; */
        box-shadow: none;

        @media only screen and (max-width: 430px) {
          width: 85%;
          margin: 10px 15px;
        }
      }
    }
  }
}

.info {
  color: #828282;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: whitesmoke;
  border-radius: 10px;
  padding: 0 10px;

  p {
    width: 90%;
    font-size: 12px;
    margin-left: 10px;

    @media only screen and (max-width: 430px) {
      font-size: 10px;
    }

    @media only screen and (max-height: 640px) {
      font-size: 7.3px;
    }
  }

  i {
    font-size: 18px;

    @media only screen and (max-width: 430px) {
      font-size: 15px;
    }

    @media only screen and (max-height: 640px) {
      font-size: 14px;
    }
  }

  span:first-child {
    color: #626262;
    font-weight: 600;
  }

  span:last-child {
    color: #000000;
  }
}

.errorInfo {
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: #fde9e7;
  border-radius: 10px;
  padding: 0 10px;

  p {
    color: #5a5a5a;
    width: 90%;
    font-size: 12px;
    margin-left: 10px;

    @media only screen and (max-width: 430px) {
      font-size: 10px;
    }

    @media only screen and (max-height: 640px) {
      font-size: 7.3px;
    }
  }

  i {
    color: #C44B4B;
    font-size: 18px;

    @media only screen and (max-width: 430px) {
      font-size: 15px;
    }

    @media only screen and (max-height: 640px) {
      font-size: 14px;
    }
  }

  span:first-child {
    color: #626262;
    font-weight: 600;
  }

  span:last-child {
    color: #000000;
  }
}

#stationSessionManagement {
  .transactionMessage {
    color: #5a5a5a;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #fde9e7;
    border-radius: 10px;
    padding: 0 10px;
    display: none;

    p {
      width: 90%;
      font-size: 12px;
      margin-left: 10px;
    }

    i {
      color: #C44B4B;
      font-size: 18px;
    }

    span {
      color: #626262;
      font-weight: 600;
    }
  }
}

.paymentGatewayContainer {
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: none;
}

.paymentGateway {
  width: 100%;
  max-width: 360px;
  height: 90%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}